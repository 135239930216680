import { debounce } from 'lodash'
import { useEffect } from 'react'

export const useResizeImageHeight = (
  loadImageState: boolean[],
  containersRefs: React.MutableRefObject<(HTMLDivElement | null)[]>,
) => {
  useEffect(() => {
    const resizeImageHeight = () => {
      containersRefs.current.forEach((container, index) => {
        if (container && loadImageState[index]) {
          const img = container.querySelector('img')
          if (img) {
            img.style.maxHeight = `${container.offsetWidth}px`
          }
        }
      })
    }

    const debounced = debounce(resizeImageHeight, 100)

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', debounced)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', debounced)
      }
    }
  }, [loadImageState])
}
