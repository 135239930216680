import { useGtmClick, usePaidContentListLoaded } from '@scm/tag-manager'

import { ActiveVideo, CtaButton, VideoList } from './components'
import { MODULE_UID, videoPlaylist } from './constants'
import { useVideoList } from './hooks'
import { VideoPlaylistProvider } from './provider'
import { VideoPlaylistProps } from './type'
import { getValidVideoList } from './utils'
import { selectors } from '@scm/redux-store'

export const VideoPlaylist = ({
  title,
  displayOptions,
  videos = [],
  ctaButton,
  moduleLocation,
  autoPlay = false,
  currentVideoIndex = 0,
}: VideoPlaylistProps) => {
  const { media } = selectors.useConfig()

  const { trackGtmClick } = useGtmClick(title, moduleLocation, MODULE_UID)

  const videoList = getValidVideoList(videos, media)

  usePaidContentListLoaded(title, MODULE_UID, videoList)

  const { currentVideo, playNext, playVideo } = useVideoList(
    videoList,
    currentVideoIndex,
  )

  if (!videoList.length) return null

  const isDarkMode = displayOptions === 'dark'
  return (
    <div data-module="video-playlist" className={videoPlaylist({ isDarkMode })}>
      <VideoPlaylistProvider
        currentVideoUid={currentVideo?.uid}
        playNext={playNext}
        autoPlay={autoPlay}
      >
        <h3 className={videoPlaylist('Title')} data-testid="playlist-title">
          {title}
        </h3>
        <div className={videoPlaylist('Wrapper')}>
          <ActiveVideo videoItem={currentVideo} trackGtmClick={trackGtmClick} />
          <VideoList
            videos={videoList}
            trackGtmClick={trackGtmClick}
            trackGtmUid={MODULE_UID}
            trackGtmTitle={title}
            onClick={playVideo}
          />
        </div>
        <CtaButton
          ctaButton={ctaButton}
          isDarkMode={isDarkMode}
          trackGtmClick={trackGtmClick}
        />
      </VideoPlaylistProvider>
    </div>
  )
}
