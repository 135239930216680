import { Keyword } from '@scm/ui-component-keyword'

import { contentSpotlight } from '../constants'
import { KeywordListProps } from '../type'

export const KeywordList = ({
  categoryUrl,
  keyWordItemStyle,
  categoryName,
  trackGtmClick,
}: KeywordListProps) => {
  if (!categoryName) return null

  return (
    <div className={contentSpotlight('KeywordWrapper')}>
      <Keyword
        className={keyWordItemStyle}
        title={categoryName}
        url={categoryUrl}
        variant="standard"
        trackGtmClick={trackGtmClick}
      />
    </div>
  )
}
