import { useContext } from 'react'

import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { VideoPlayer } from '@scm/ui-component-video-player'
import { formatDate } from '@scm/ui-module-content-preview'

import { videoPlaylist } from '../constants'
import { VideoPlaylistContext } from '../provider'
import { ActiveVideoItemProp } from '../type'
import { getViewTimeFormDuration } from '../utils'

export const ActiveVideo = ({
  videoItem,
  trackGtmClick,
}: ActiveVideoItemProp) => {
  const { isVideoStarted, setIsVideoStarted, playNext } =
    useContext(VideoPlaylistContext)
  if (!videoItem) return null

  const {
    video,
    duration,
    thumbnail,
    videoTitle,
    mobileVideoTitle,
    videoUrl,
    publishedDate,
    isSponsored,
  } = videoItem

  const formattedDuration = getViewTimeFormDuration(duration)
  return (
    <div
      className={videoPlaylist('PlayerWrapper')}
      data-testid="video-playlist-active"
    >
      <VideoPlayer
        video={video}
        thumbnailImage={thumbnail}
        isPlaylist={true}
        isVideoStarted={isVideoStarted}
        setIsVideoStarted={setIsVideoStarted}
        playNext={playNext}
      />
      {isSponsored && (
        <div className={videoPlaylist('KeywordWrapper')}>
          <Keyword
            variant="alternate"
            url={videoUrl}
            trackGtmClick={trackGtmClick}
            title="Sponsored Content"
            disabled
            className={videoPlaylist('Keyword')}
          />
        </div>
      )}
      <Link
        to={videoUrl}
        trackGtmClick={trackGtmClick}
        className={videoPlaylist('ActiveVideoLink')}
      >
        <div
          className={videoPlaylist('ActiveVideoTitle')}
          data-testid="active-video-title"
        >
          {videoTitle}
        </div>
        <div
          className={videoPlaylist('ActiveVideoMobileTitle')}
          data-testid="active-video-mobile-title"
        >
          {mobileVideoTitle || videoTitle}
        </div>
      </Link>
      <div className={videoPlaylist('ActiveVideoFooter')}>
        <span
          className={videoPlaylist('ActiveVideoDate')}
          data-testid="video-published-date"
        >
          {formatDate(publishedDate)}
        </span>
        {formattedDuration && (
          <>
            <span className={videoPlaylist('ActiveVideoSeparator')}>|</span>
            <span
              className={videoPlaylist('ActiveVideoViewTime')}
              data-testid="acive-video-view-time"
            >
              {formattedDuration}
            </span>
          </>
        )}
      </div>
    </div>
  )
}
