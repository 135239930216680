import { Button } from '@scm/ui-component-button'
import { Link } from '@scm/ui-component-link'

import { videoPlaylist } from '../constants'
import { CtaButtonProp } from '../type'

export const CtaButton = ({
  ctaButton,
  isDarkMode,
  trackGtmClick,
}: CtaButtonProp) => {
  const { buttonUrl, buttonTitle } = ctaButton || {}

  if (!buttonTitle || !buttonUrl) return null

  const isExternalUrl = buttonUrl.startsWith('http')
  const targetUrl = isExternalUrl ? '_blank' : '_self'
  return (
    <Link
      to={buttonUrl}
      trackGtmClick={trackGtmClick}
      className={videoPlaylist('CtaButtonLink')}
      target={targetUrl}
    >
      <Button
        className={videoPlaylist('CtaButton')}
        variant={isDarkMode ? 'light-outlined' : 'dark-outlined'}
        size="medium"
        url={buttonUrl}
      >
        {buttonTitle}
      </Button>
    </Link>
  )
}
