import { ContentType } from '@scm/ui-component-article-read-time'
import { IconType } from '@scm/ui-component-icon'

export const getContentIconUrl = (
  contentType: ContentType,
): IconType | null => {
  switch (contentType) {
    case 'Article':
      return null
    case 'Document':
      return 'Document'
    case 'Magazine':
      return null
    case 'Video':
      return 'Play'
    case 'Webinar':
      return 'ChalkBoard'
    case 'Podcast':
      return 'Microphone'
    case 'Slideshow':
      return 'Images'
    default:
      return null
  }
}
