import { useContext } from 'react'

import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'

import { videoPlaylist } from '../constants'
import { VideoPlaylistContext } from '../provider'
import { VideoItemProp } from '../type'
import { formatDuration } from '../utils'
import { handlePaidContentClickEvent } from '@scm/tag-manager'

export const VideoListItem = ({
  videoItem,
  onClick,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
}: VideoItemProp) => {
  const {
    duration,
    thumbnail,
    videoTitle,
    mobileVideoTitle,
    videoUrl,
    uid,
    isSponsored,
  } = videoItem
  const { isVideoStarted, currentVideoUid, setIsVideoStarted } =
    useContext(VideoPlaylistContext)
  const play = () => {
    onClick(uid)
    setIsVideoStarted(true)
  }

  const isActive = uid === currentVideoUid && isVideoStarted
  return (
    <>
      <div
        className={videoPlaylist('VideoItemThumbnailAContainer')}
        onClick={play}
        role="presentation"
      >
        <div
          className={videoPlaylist('VideoItemThumbnailAspect', {
            isActive,
          })}
        >
          <Image
            loading="eager"
            src={thumbnail.src}
            alt={thumbnail.alt}
            width={300}
            quality={95}
            fallback="brand"
            format="jpg"
            draggable="false"
            className={videoPlaylist('VideoItemThumbnail')}
          />
          <div
            className={videoPlaylist('VideoItemOverlay', {
              isActive,
            })}
            data-testid="video-item-overlay"
          >
            <p
              className={videoPlaylist('VideoItemOverlayText', {
                isActive,
              })}
            >
              Now playing
            </p>
          </div>
        </div>
        <div
          className={videoPlaylist('VideoItemInfo', { isActive })}
          data-testid="video-item-info"
        >
          <Icon icon="Play" className={videoPlaylist('VideoItemIcon')} />
          <span className={videoPlaylist('VideoItemDuration')}>
            {formatDuration(duration)}
          </span>
        </div>
      </div>
      {isSponsored && (
        <div className={videoPlaylist('KeywordWrapper')}>
          <Keyword
            variant="alternate"
            url={videoUrl}
            trackGtmClick={trackGtmClick}
            title="Sponsored Content"
            disabled
            className={videoPlaylist('Keyword')}
          />
        </div>
      )}
      <Link
        to={videoUrl}
        trackGtmClick={trackGtmClick}
        className={videoPlaylist('VideoItemLink')}
        trackPaidContentClick={() =>
          handlePaidContentClickEvent(videoItem, trackGtmTitle, trackGtmUid)
        }
      >
        <div
          className={videoPlaylist('VideoItemTitle')}
          data-testid="video-item-title"
        >
          {videoTitle}
        </div>
        <div
          className={videoPlaylist('VideoItemMobileTitle')}
          data-testid="video-item-mobile-title"
        >
          {mobileVideoTitle}
        </div>
      </Link>
    </>
  )
}
