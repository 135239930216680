import { useRef, useState } from 'react'

import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'

import { linkedImage } from './constants'
import { LogoListProps } from './type'
import { useResizeImageHeight } from './useResizeImageHeight'

export const LogoList = ({ logos, trackGtmClick }: LogoListProps) => {
  const containersRefs = useRef<Array<HTMLDivElement | null>>([])
  const [loadImageState, setImageLoadState] = useState<boolean[]>(
    Array(logos.length).fill(false),
  )

  useResizeImageHeight(loadImageState, containersRefs)

  return (
    <div className={linkedImage('LogoListWrapper')}>
      <div className={linkedImage('LogoList')}>
        {logos.map(({ url, src, altText }, index) => (
          <div
            className={linkedImage('LogoWrapper')}
            ref={el => (containersRefs.current[index] = el)}
            key={index}
          >
            <Link
              to={url}
              target={url.startsWith('http') ? '_blank' : '_self'}
              trackGtmClick={trackGtmClick}
            >
              <Image
                className={linkedImage('Logo')}
                src={src}
                alt={altText ?? ''}
                fallback="brand"
                width={300}
                onLoad={() => {
                  setImageLoadState(state =>
                    state.map((loaded, i) => (i === index ? true : loaded)),
                  )
                }}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
