import { VideoTemplateFragment } from '@scm/content-domain'
import { MediaContextProps } from '@scm/ui-component-media'
import { getVideoProps } from '@scm/ui-component-video-player'

import { VideoPreviewProps } from './type'

export const getCurrentVideoIndex = (
  videos: VideoPreviewProps[],
  uid: string,
) => videos.findIndex(video => video.uid === uid)

const isValidVideo = (
  video: VideoTemplateFragment['video'],
  mediaContext: MediaContextProps,
) => {
  const videoSource = video?.videoService?.toLocaleLowerCase() ?? ''

  return videoSource !== 'embed' && !!getVideoProps(video, mediaContext)
}

export const getValidVideoList = (
  videos: VideoPreviewProps[],
  mediaContext: MediaContextProps,
) => videos.filter(videoItem => isValidVideo(videoItem.video, mediaContext))

export const isValidDuration = (duration: string) => duration !== '00:00:00'

export const formatDuration = (duration: string) => {
  if (!isValidDuration(duration)) return ''

  return duration.slice(0, 3) === '00:' ? duration.slice(3) : duration
}

export const getFormattedHours = (hours: number) => {
  const hoursLabel = hours > 1 ? ' Hours ' : ' Hour '
  return hours ? hours + hoursLabel : ''
}

export const getFormattedMins = (mins: number) => {
  const minsLabel = mins > 1 ? ' Mins' : ' Min'
  return mins ? mins + minsLabel : ''
}

export const getViewTimeFormDuration = (duration: string) => {
  const [hours, mins] = duration.split(':').map(el => parseInt(el))
  const viewTime = getFormattedHours(hours) + getFormattedMins(mins)
  return viewTime ? viewTime + ' View' : ''
}
