import { useGtmClick } from '@scm/tag-manager'
import { ImageDisplayOptions } from '@scm/content-domain'

import { MODULE_UID, linkedImage } from './constants'
import { CtaButton } from './linkedImageCtaButton'
import { LogoList } from './linkedImageLogoList'
import { LinkedImageProps } from './type'

export const LinkedImage = ({
  title = '',
  logos,
  ctaButton,
  moduleLocation,
  contentDisplayOption,
}: LinkedImageProps) => {
  const { trackGtmClick } = useGtmClick(title, moduleLocation, MODULE_UID)

  return (
    <div
      data-module="linked-image"
      className={linkedImage({
        isLarge: contentDisplayOption === ImageDisplayOptions.Large,
        moduleLocation,
      })}
    >
      <div className={linkedImage('Header')}>
        <p className={linkedImage('Title')} data-testid="linked-image-title">
          {title}
        </p>
        <CtaButton
          ctaButton={ctaButton}
          modificator="default"
          trackGtmClick={trackGtmClick}
        />
      </div>
      {logos && logos.length > 0 && (
        <LogoList
          logos={logos}
          contentDisplayOption={contentDisplayOption}
          trackGtmClick={trackGtmClick}
        />
      )}
      <CtaButton
        ctaButton={ctaButton}
        modificator="mobile"
        trackGtmClick={trackGtmClick}
      />
    </div>
  )
}
