import { Button } from '@scm/ui-component-button'
import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'

import { publicationPromo, publicationPromoSidebar } from './constants'
import { RelatedContent } from './relatedContent'
import { PublicationPromoProps } from './type'

export const PublicationPromo = ({
  title,
  ctaButtonText,
  promoLabel,
  promoPublication,
  relatedContent,
  moduleLocation = 'homepage',
}: PublicationPromoProps) => {
  const { altText, image, url, summary } = promoPublication
  const variantClassName =
    moduleLocation == 'sidebar' ? publicationPromoSidebar : publicationPromo

  return (
    <div data-module="publication-promo" className={variantClassName()}>
      <h2
        className={variantClassName('Heading')}
        data-testid="publication-promo-heading"
      >
        {title}
      </h2>
      <div className={variantClassName('Body')}>
        <div className={variantClassName('ImageSection')}>
          <div className={variantClassName('ImageWrapper')}>
            <div className={variantClassName('ImageAspectWrapper')}>
              <Link to={url} aria-label="Open Publication">
                <Image
                  alt={altText ?? ''}
                  className={variantClassName('Image')}
                  fallback="brand"
                  src={image}
                />
              </Link>
            </div>
          </div>

          <Link to={url}>
            <Button
              className={variantClassName('Button')}
              size="medium"
              variant="secondary-filled"
            >
              {ctaButtonText}
            </Button>
          </Link>
        </div>
        <div className={variantClassName('ContentSection')}>
          <p className={variantClassName('Label')}>{promoLabel}</p>
          <Link to={url}>
            <h3
              className={variantClassName('ContentTitLe')}
              data-testid="publication-promo-title"
            >
              {promoPublication.title}
            </h3>
          </Link>

          <p
            className={variantClassName('Summary')}
            data-testid="publication-promo-summary"
          >
            {summary}
          </p>
          <RelatedContent
            className={variantClassName('RelatedContentDesktop')}
            content={relatedContent}
            moduleLocation={moduleLocation}
          />
        </div>
        {moduleLocation !== 'sidebar' ? (
          <RelatedContent
            className={variantClassName('RelatedContentMobile')}
            content={relatedContent}
          />
        ) : null}
      </div>
    </div>
  )
}
