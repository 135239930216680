import { Button } from '@scm/ui-component-button'
import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'

import { linkedImage } from './constants'
import { CtaButtonProp } from './type'

export const CtaButton = ({
  ctaButton,
  modificator,
  trackGtmClick,
}: CtaButtonProp) => {
  const { buttonTitle, buttonUrl } = ctaButton || {}

  if (!buttonTitle || !buttonUrl) return null

  const isExternalUrl = buttonUrl.startsWith('http')
  const targetUrl = isExternalUrl ? '_blank' : '_self'

  const mobileCtaButton = (
    <div className={linkedImage('MobileCtaWrapper')}>
      <Button
        className={linkedImage('MobileCtaButton')}
        variant="text-link"
        size="medium"
        url={buttonUrl}
        rightIcon="ChevronRight"
        trackGtmClick={trackGtmClick}
        target={targetUrl}
        buttonLikeAnchor={isExternalUrl}
      >
        {buttonTitle}
      </Button>
    </div>
  )

  const defaultCtaButton = (
    <Link
      className={linkedImage('DefaultCtaWrapper')}
      to={buttonUrl}
      trackGtmClick={trackGtmClick}
      target={targetUrl}
    >
      <span className={linkedImage('DefaultCtaButton')}>{buttonTitle}</span>
      <Icon icon="ChevronRight" className={linkedImage('DefaultCtaIcon')} />
    </Link>
  )

  return <>{modificator === 'mobile' ? mobileCtaButton : defaultCtaButton}</>
}
