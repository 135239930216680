import { ArticleReadTime } from '@scm/ui-component-article-read-time'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { heroContentSpotlight } from '../constants'

import { StandardContentSpotlightProps } from '../type'
import {
  formatDate,
  getContentIconUrl,
  getSummaryFromBody,
} from '@scm/ui-module-content-preview'
import { handlePaidContentClickEvent } from '@scm/tag-manager'

export const HeroContentSpotlight = ({
  content,
  contentType,
  listPageUrl,
  variant,
  moduleLocation,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
}: StandardContentSpotlightProps) => {
  const {
    image,
    heading,
    mobileHeading,
    articleSummary,
    articleBody,
    timeRead,
    date,
    articleUrl,
    categoryName,
    categoryUrl,
    linkAttrs,
  } = content
  const icon = getContentIconUrl(contentType)
  const isSponsored = categoryName === 'Sponsored Content'
  const contentSummary = articleSummary || getSummaryFromBody(articleBody)

  return (
    <div
      className={heroContentSpotlight({
        isReversed: variant === 'reversed',
        isSidebar: moduleLocation === 'sidebar',
      })}
      data-module="hero-content-spotlight"
    >
      <div className={heroContentSpotlight('ImageSection')}>
        <div className={heroContentSpotlight('ImageContainer')}>
          <div className={heroContentSpotlight('ImageAspectWrapper')}>
            <Link
              to={articleUrl}
              trackGtmClick={trackGtmClick}
              {...linkAttrs}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(content, trackGtmTitle, trackGtmUid)
              }
            >
              <Image
                className={heroContentSpotlight('Image')}
                src={image.url}
                alt={image.altText}
                fallback="brand"
                width={300}
              />
            </Link>
          </div>
        </div>
        {icon && (
          <Link
            className={heroContentSpotlight('IconWrapper')}
            to={listPageUrl}
            trackGtmClick={trackGtmClick}
            title={`Link to all ${contentType.toLowerCase()}`}
            aria-label="Redirect to All page"
            {...linkAttrs}
            data-testid="hero-content-spotlight-icon-link"
          >
            <Icon icon={icon} className={heroContentSpotlight('Icon')} />
          </Link>
        )}
      </div>
      <div className={heroContentSpotlight('TitleSection')}>
        {isSponsored && (
          <div className={heroContentSpotlight('KeywordWrapper')}>
            <Keyword
              variant="alternate"
              url={categoryUrl}
              trackGtmClick={trackGtmClick}
              title="Sponsored Content"
              disabled={isSponsored}
              className={heroContentSpotlight('Keyword')}
            />
          </div>
        )}
        <div className={heroContentSpotlight('TitleWrapper')}>
          <Link
            to={articleUrl}
            trackGtmClick={trackGtmClick}
            className={heroContentSpotlight('Title', {
              displayOption: 'default',
            })}
            data-testid="hero-spotlight-default-title"
            {...linkAttrs}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(content, trackGtmTitle, trackGtmUid)
            }
          >
            {heading}
          </Link>
          <Link
            to={articleUrl}
            trackGtmClick={trackGtmClick}
            className={heroContentSpotlight('Title', {
              displayOption: 'mobile',
            })}
            data-testid="hero-spotlight-mobile-title"
            {...linkAttrs}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(content, trackGtmTitle, trackGtmUid)
            }
          >
            {mobileHeading || heading}
          </Link>
        </div>
        <div
          data-testid="hero-spotlight-summary"
          className={heroContentSpotlight('Summary')}
        >
          {contentSummary}
        </div>
        <div className={heroContentSpotlight('Footer')}>
          <span className={heroContentSpotlight('Date')}>
            {formatDate(date)}
          </span>
          {timeRead ? (
            <div className={heroContentSpotlight('ReadTimeWrapper')}>
              <span className={heroContentSpotlight('FooterSeparator')}>|</span>
              <ArticleReadTime
                minutes={timeRead}
                classNames={heroContentSpotlight('ReadTime')}
                size="small"
                contentType={contentType}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
