import { Link } from '@scm/ui-component-link'
import { renderMarkdown } from '@scm/ui-core'

import {
  publicationPromo,
  publicationPromoSidebar,
  relatedContentLength,
} from './constants'
import { RelatedContentProps } from './type'

export const RelatedContent = ({
  content,
  className,
  moduleLocation = 'homepage',
}: RelatedContentProps) => {
  const variantClassName =
    moduleLocation == 'sidebar' ? publicationPromoSidebar : publicationPromo
  return (
    <div className={className}>
      {content
        .slice(0, relatedContentLength)
        .map(({ categoryUrl, categoryName, url, title }, index) => (
          <div
            className={variantClassName('RelatedItem')}
            key={index}
            data-testid="publication-promo-related-item"
          >
            <Link
              to={categoryUrl ?? ''}
              data-testid="publication-promo-related-category"
            >
              <p className={variantClassName('RelatedItemCategory')}>
                {categoryName}
              </p>
            </Link>
            <Link to={url} data-testid="publication-promo-related-title">
              <p
                className={variantClassName('RelatedItemTitle')}
                dangerouslySetInnerHTML={{ __html: renderMarkdown(title) }}
              />
            </Link>
          </div>
        ))}
    </div>
  )
}
