import { Ads } from '@scm/ads'
import { Button } from '@scm/ui-component-button'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'
import { renderMarkdown } from '@scm/ui-core'

import { StandardContentSpotlightProps } from '../type'
import { getContentIconUrl } from '../utils'
import { contentSpotlight } from '../constants'

import { KeywordList } from './keywordList'
import { handlePaidContentClickEvent } from '@scm/tag-manager'

export const StandardContentSpotlight = ({
  content,
  variant,
  mode,
  textAlignment,
  adPosition,
  rectangleAdPos,
  contentType,
  listPageUrl,
  moduleLocation,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
  ...otherProps
}: StandardContentSpotlightProps) => {
  const {
    image,
    heading,
    summary,
    body,
    callToAction,
    articleUrl,
    categoryName,
    categoryUrl,
    linkAttrs,
  } = content

  const icon = getContentIconUrl(contentType)

  return (
    <div
      data-module="content-spotlight"
      className={contentSpotlight({
        hasAd: !!rectangleAdPos && !!adPosition,
        mode,
      })}
    >
      {/* eslint-disable react/no-unknown-property */}

      <article
        className={contentSpotlight('Article', {
          isReversed: variant === 'reversed',
          isSidebar: moduleLocation === 'sidebar',
        })}
        {...otherProps}
        text-alignment={textAlignment}
        data-testid="content-spotlight-article"
      >
        <div
          className={contentSpotlight('ImageContainer')}
          data-testid="content-spotlight-image-container"
        >
          <div className={contentSpotlight('KeywordList')}>
            <KeywordList
              keyWordItemStyle={contentSpotlight('Keyword')}
              categoryName={categoryName}
              categoryUrl={categoryUrl}
              trackGtmClick={trackGtmClick}
            />
          </div>
          <Link
            to={articleUrl ?? '#'}
            trackGtmClick={trackGtmClick}
            {...linkAttrs}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(content, trackGtmTitle, trackGtmUid)
            }
          >
            <Image
              src={image.url}
              alt={image.altText}
              fallback="contributor"
              className={contentSpotlight('Image')}
            />
          </Link>
          {icon && (
            <Link
              className={contentSpotlight('IconWrapper')}
              title={`Link to all ${contentType.toLowerCase()}`}
              to={listPageUrl}
              trackGtmClick={trackGtmClick}
              data-testid="content-spotlight-icon-link"
            >
              <Icon icon={icon} className={contentSpotlight('Icon')} />
            </Link>
          )}
        </div>
        <div
          className={contentSpotlight('ContentContainer', {
            textAlignment,
            isSidebar: moduleLocation === 'sidebar',
          })}
          data-testid="content-spotlight-content-container"
        >
          <p
            className={contentSpotlight('ContentSummary')}
            data-testid="content-spotlight-summary"
          >
            {summary}
          </p>
          <Link
            to={articleUrl ?? '#'}
            trackGtmClick={trackGtmClick}
            {...linkAttrs}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(content, trackGtmTitle, trackGtmUid)
            }
          >
            <h2
              className={contentSpotlight('ContentHeading')}
              data-testid="content-spotlight-heading"
              dangerouslySetInnerHTML={{ __html: renderMarkdown(heading) }}
            />
          </Link>
          {body && (
            <div
              className={contentSpotlight('ContentBodyWrapper', {
                isSidebar: moduleLocation === 'sidebar',
              })}
            >
              <p
                className={contentSpotlight('ContentBody')}
                data-testid="content-spotlight-body"
                dangerouslySetInnerHTML={{ __html: renderMarkdown(body) }}
              />
            </div>
          )}
          <div className={contentSpotlight('ButtonContainer')}>
            <Link
              to={callToAction.url}
              trackGtmClick={trackGtmClick}
              data-testid="content-spotlight-button-link"
              {...linkAttrs}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(content, trackGtmTitle, trackGtmUid)
              }
            >
              <Button
                variant={mode === 'light' ? 'dark-outlined' : 'light-outlined'}
                size="medium"
              >
                {callToAction.text}
              </Button>
            </Link>
          </div>
        </div>
      </article>
      {/* rectangle ad */}
      {adPosition && rectangleAdPos ? (
        <div
          className={contentSpotlight('AdContainer', { adPosition })}
          data-testid="content-spotlight-ad-container"
        >
          <Ads.Ad
            pos={rectangleAdPos}
            sizes={[
              [300, 100],
              [300, 250],
            ]}
            responsiveSizeMapping={[
              {
                width: 0,
                height: 0,
                sizes: [
                  [300, 100],
                  [300, 250],
                ],
              },
              {
                width: 480,
                height: 200,
                sizes: [
                  [300, 100],
                  [300, 250],
                ],
              },
            ]}
          />
        </div>
      ) : null}
    </div>
  )
}
