import { useState } from 'react'

import { VideoPreviewProps } from '../type'
import { getCurrentVideoIndex } from '../utils'

export const useVideoList = (
  videos: VideoPreviewProps[],
  currentVideoIndex?: number,
) => {
  const [currentVideo, setCurrentVideo] = useState(
    videos[currentVideoIndex ?? 0],
  )
  const playNext = () => {
    const currentVideoIndex = getCurrentVideoIndex(videos, currentVideo.uid)
    const nextVideoIndex = currentVideoIndex + 1

    if (nextVideoIndex < videos.length) {
      setCurrentVideo(videos[nextVideoIndex])
    } else {
      setCurrentVideo(videos[0])
    }
  }

  const playVideo = (uid: string) => {
    const videoIndex = getCurrentVideoIndex(videos, uid)

    if (videoIndex < videos.length) {
      setCurrentVideo(videos[videoIndex])
    } else {
      setCurrentVideo(videos[0])
    }
  }

  return { currentVideo, setCurrentVideo, playNext, playVideo }
}
