import { useContext, useEffect, useRef } from 'react'

import { useScreen } from '@scm/ui-tailwind'

import { videoPlaylist } from '../constants'
import { VideoPlaylistContext } from '../provider'
import { VideoListProps } from '../type'
import { VideoListItem } from './videoListItem'

export const VideoList = ({
  videos,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
  onClick,
}: VideoListProps) => {
  const videosRef = useRef<{ [key: string]: HTMLDivElement }>({})
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { currentVideoUid, isVideoStarted } = useContext(VideoPlaylistContext)
  const screen = useScreen()

  const scroll = (uid: string) => {
    if (screen === 'xl' || screen === 'lg') {
      const container = containerRef?.current

      container?.scrollTo({
        top: videosRef.current[uid].offsetTop,
        behavior: 'smooth',
      })
    } else {
      videosRef.current[uid]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }
  }

  useEffect(() => {
    if (isVideoStarted && currentVideoUid) {
      scroll(currentVideoUid)
    }
  }, [currentVideoUid, isVideoStarted])

  if (!videos.length) return null

  return (
    <div
      className={videoPlaylist('VideoListWrapper')}
      data-testid="video-list"
      ref={containerRef}
    >
      <div className={videoPlaylist('VideoList')}>
        {videos.map(videoItem => {
          return (
            <div
              className={videoPlaylist('VideoItem')}
              key={videoItem.uid}
              data-testid="video-list-item"
              ref={el => el && (videosRef.current[videoItem.uid] = el)}
            >
              <VideoListItem
                videoItem={videoItem}
                trackGtmClick={trackGtmClick}
                trackGtmUid={trackGtmUid}
                trackGtmTitle={trackGtmTitle}
                onClick={onClick}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
