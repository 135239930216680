import { useGtmClick, usePaidContentListLoaded } from '@scm/tag-manager'

import { ContentSpotlightProps } from './type'
import { MODULE_UID } from './constants'
import { StandardContentSpotlight, HeroContentSpotlight } from './components'

export const ContentSpotlight = (props: ContentSpotlightProps) => {
  const { content, moduleDesign, moduleLocation = 'homepage', title } = props

  const { trackGtmClick } = useGtmClick(
    content.heading,
    moduleLocation,
    MODULE_UID,
  )
  const isHero = moduleDesign === 'hero'

  usePaidContentListLoaded(title ?? content.heading, MODULE_UID, [content])

  return (
    <>
      {isHero ? (
        <HeroContentSpotlight
          {...props}
          trackGtmClick={trackGtmClick}
          trackGtmUid={MODULE_UID}
          trackGtmTitle={title}
        />
      ) : (
        <StandardContentSpotlight
          {...props}
          trackGtmClick={trackGtmClick}
          trackGtmUid={MODULE_UID}
          trackGtmTitle={title}
        />
      )}
    </>
  )
}
